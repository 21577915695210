import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { Search } from '../components/search-by-att';

function CategoryTemplate({ data, pageContext }) {
  return (
    <Search att="Category" data={data} pageContext={pageContext} />
  );
}

export const query = graphql`
query wpCategoryTemplate($uri: String, $categories: [Int], $limit: Int, $skip: Int) {
  allAtts: allWpCategory {
    nodes {
      uri
      name
      databaseId
      wpParent {
        node {
          name
          wpParent {
            node {
              name
            }
          }
        }
      }
    }
  }
  wpPage: wpCategory(uri: {eq: $uri}) {
    uri
    name
    databaseId
    ...SeoCategory
  }
  posts: allWpPost(
    filter: {categories: {nodes: {elemMatch: {databaseId: {in: $categories}}}}}
    limit: $limit
    skip: $skip
    sort: {order: DESC, fields: date}
  ) {
    nodes {
      title
      date
      schemaMarkup {
        eaPostSchemaUploadDate
        eaPostSchemaVideoName
      }
      excerpt
      uri
      featuredImage {
        node {
          gatsbyImage(width:300)
        }
      }
    }
  }
}
`;

CategoryTemplate.propTypes = {
  data: object,
  pageContext: object,
};

CategoryTemplate.defaultProps = {
  data: {},
  pageContext: {},
};

export default CategoryTemplate;
export { Head } from '../components/seo/seo';
